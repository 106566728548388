.outline-drop-down-picker-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.outline-drop-down-picker-container label {
  color: var(--gray-600, #404040);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: 0.02625rem;
}

.outline-drop-down-picker {
  color: var(--gray-700, #303030);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02625rem;
  width: 100%;
  background: var(--grey-030, #fafafa);
  border-radius: 5px;
}

.outline-drop-down-picker mat-select {
  padding: 0 0.5rem;
}

.outline-drop-down-picker .mat-form-field-wrapper .mat-form-field-infix {
  padding: 0.6rem 0 0.4rem 0 !important;
  margin: 0 !important;
  border: none !important;
  line-height: 1rem !important;
}

.outline-drop-down-picker .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
  height: 0.2rem !important;
}

.outline-drop-down-picker .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

.outline-drop-down-picker .mat-form-field-wrapper .mat-form-field-infix mat-select {
  padding: 0 !important;
}

.outline-drop-down-picker .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 5px;
  background: var(--grey-030, #fafafa);
  height: 1.75rem;
  color: var(--gray-200, #cfcfcf);
}

.unit-picker .outline-drop-down-picker {
  width: 60px;
  display: block;
}

.outline-drop-down-picker .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  margin-top: 1rem !important;
}

.outline-drop-down-picker .mat-form-field-wrapper .mat-form-field-subscript-wrapper mat-error {
  margin-top: 0.1rem !important;
}
