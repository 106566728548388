.outline-input-field-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.outline-input-field-container label {
  color: var(--gray-600, #404040);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: 0.02625rem;
}

.outline-input-field {
  color: var(--gray-700, #303030);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02625rem;
  width: 100%;
  background: var(--grey-030, #fafafa);
  border-radius: 5px;
}

.outline-input-field .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

.outline-input-field .mat-form-field-wrapper .mat-form-field-infix {
  padding: 0.6rem 0 0.4rem 0 !important;
  margin: 0 !important;
  border: none !important;
  line-height: 1rem !important;
}
