.standard-mat-table-container {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
}

.standard-mat-table {
  width: 100%;
  color: var(--gray-900);
  font-size: 0.8rem !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.standard-mat-table .mat-sort-header-arrow {
  color: var(--ColorScale3) !important;
}

.standard-mat-table td,
.standard-mat-table th {
  padding: 5px 0.5rem !important;
  vertical-align: middle;
  text-align: left;
}
.standard-mat-table td.ignore-style,
.standard-mat-table th.ignore-style {
  background-color: var(--white) !important;
  border: none !important;
}
.standard-mat-table .no-cell-padding {
  padding: 0px !important;
}

.standard-mat-table thead th:not(.ignore-style),
.standard-mat-table tfoot tr td:not(.ignore-style) {
  background-color: var(--table-300) !important;
  font-weight: bold;
  font-size: 0.8rem;
}
.standard-mat-table thead tr:first-of-type th:not(.ignore-style):first-of-type {
  border-top-left-radius: 0.5rem !important;
}
.standard-mat-table thead tr:first-of-type th:not(.ignore-style):last-of-type {
  border-top-right-radius: 0.5rem !important;
}

.standard-mat-table tbody tr:nth-of-type(odd) td {
  background-color: var(--table-100);
}

.standard-mat-table tbody tr:nth-of-type(even) td {
  background-color: #ffffff;
}

.standard-mat-table tbody td:not(.ignore-style):first-of-type {
  border-left: 1px solid var(--table-100) !important;
}
.standard-mat-table tbody td:not(.ignore-style):last-of-type {
  border-right: 1px solid var(--table-100) !important;
}

.standard-mat-table tfoot tr:last-of-type td:not(.ignore-style):first-of-type {
  border-bottom-left-radius: 0.5rem !important;
}
.standard-mat-table tfoot tr:last-of-type td:not(.ignore-style):last-of-type {
  border-bottom-right-radius: 0.5rem !important;
}

table[range-selection] th,
table[range-selection] td,
table[range-selection] th > *,
table[range-selection] td > * {
  user-select: none !important;
}
