.excel-table-container {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
}

.excel-table {
  width: 100%;
  color: var(--gray-900);
  font-size: 0.8rem !important;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
}

.excel-table td,
.excel-table th {
  padding: 5px 0.5rem !important;
  vertical-align: middle;
  text-align: left;
}

.excel-table thead th,
.excel-table tbody tr td,
.excel-table tfoot tr td {
  border: 1px solid #d4d4d4 !important;
}
