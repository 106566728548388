.outline-date-range-picker-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.outline-date-range-picker-container label {
  color: var(--gray-600, #404040);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: 0.02625rem;
}

.date-container {
  display: flex;
  height: 1.75rem;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--gray-200, #cfcfcf);
  background: var(--grey-030, #fafafa);
}

.date-container:hover {
  border: 2px solid var(--gray-200, #cfcfcf);
  padding: 0.25rem calc(0.5rem - 1px);
}

.date-container mat-icon {
  height: 1.1rem;
  width: 1.1rem;
  line-height: 1.1rem;
  font-size: 1.1rem;
  margin: 0px !important;
  cursor: pointer;
}

.date-container label {
  color: var(--gray-800, #202020);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.02625rem;
  cursor: pointer;
}

.outline-date-range-picker-form-field {
  visibility: hidden;
  height: 0px !important;
}
