
.excel-input-field .mat-form-field-wrapper {
    padding-bottom: 0rem !important;
}

.excel-input-field {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: text !important;
}
.excel-input-field, .excel-input-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    margin-left: 0px !important;
    text-align: center !important;
}

.excel-input-field.mat-focused .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
    display: none !important;
}

.excel-input-field.mat-form-field-should-float .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
    display: none !important;
}

.excel-input-field .mat-form-field-wrapper .mat-form-field-underline {
    display: none !important;
}
  
.excel-input-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    width: unset !important;
    border: none !important;
}