.mat-form-field-custom {
  color: var(--primary-text);
  width: 100%;
}

.mat-form-field-custom .mat-form-field-wrapper {
  padding-bottom: 0rem;
}
.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-flex {
  padding: 0rem !important;
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-underline {
  bottom: 0.4rem !important;
  background-color: var(--gray-200);
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-underline::before {
  background-color: lightgrey;
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: 100% !important;
  padding: 0.25em 0 0.75em 0;
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger {
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  display: flex;
  width: 100%;
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value {
  max-width: unset !important;
  color: var(--primary-text) !important;
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
  height: 0.2rem !important;
}

mat-error {
  margin-top: 0.5rem;
}

.mat-form-field-custom.mat-form-field-type-mat-input .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  color: var(--primary-text);
  margin-top: 1px;
}
.mat-form-field-custom.mat-form-field-type-mat-input .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-empty {
  color: var(--primary-text);
  margin-top: -0.1rem;
}

.mat-form-field-custom.mat-form-field-type-mat-autocomplete .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  color: var(--primary-text);
  margin-top: -0.5rem;
}
.mat-form-field-custom.mat-form-field-type-mat-autocomplete .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-empty {
  color: var(--primary-text);
  margin-top: -0.5rem;
}

.mat-form-field-custom.mat-form-field-type-mat-select .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  color: var(--primary-text);
  margin-top: -0.5rem;
}
.mat-form-field-custom.mat-form-field-type-mat-select .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-empty {
  color: var(--primary-text);
  margin-top: 0rem;
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  padding: 0px;
}

.mat-form-field-custom .mat-select-trigger .mat-select-value {
  color: var(--primary-text) !important;
}

.mat-form-field-custom .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  color: var(--primary-text) !important;
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-datepicker-toggle {
  color: var(--primary-text);
}

.mat-form-field-custom .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  color: var(--primary-text);
}

.mat-expansion-toggle-indicator-before .mat-content.mat-content-hide-toggle {
  margin-left: 0px !important;
}

.catalogue-menu {
  min-height: 0px !important;
}

.mat-menu-item {
  height: auto !important;
}
.menu-item-container {
  display: flex !important;
  padding: 0.5rem 2rem 0.5rem 0.25rem !important;
  align-items: center !important;
  gap: 0.5rem !important;
  align-self: stretch !important;
  border-radius: 4px !important;
}

.menu-item-container mat-icon {
  height: 1rem !important;
  width: 1rem !important;
  font-size: 1rem !important;
  line-height: 1rem !important;
  margin: 0px !important;
  color: var(--gray-600, #404040) !important;
}

.menu-item-container label {
  color: var(--gray-600, #404040) !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1rem !important;
  letter-spacing: 0.02625rem !important;
  cursor: pointer !important;
}

::ng-deep .menu-item-container .mat-menu-submenu-icon {
  right: 0.5rem !important;
}
