@import '../../../../node_modules/reset-css/reset.css';
@import '../../../../node_modules/normalize.css/normalize.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700');
@font-face {
  font-family: 'Foodop';
  src: local('Foodop'), url(../assets/fonts/FOODOP_font.otf) format('opentype');
}

@import '../../../foodop-lib/src/assets/styles/styles.css';
@import '../../../foodop-lib/src/assets/styles/print-styles.css';
@import '../../../foodop-lib/src/assets/styles/table-styles.css';
@import '../../../foodop-lib/src/assets/styles/mat-component.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/outline-drop-down-picker.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/outline-date-range-picker.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/mat-menu.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/mat-table.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/excel-table.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/outline-input-field.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/outline-autocomplete-field.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/excel-input-field.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/mat-badge.css';
@import '../../../foodop-lib/src/assets/styles/standard-components/mat-snackbar.css';
