.outline-autocomplete-field-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.outline-autocomplete-field-container label {
  color: var(--gray-600, #404040);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.225rem */
  letter-spacing: 0.02625rem;
}

.outline-autocomplete-field {
  color: var(--gray-700, #303030);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02625rem;
  width: 100%;
  background: var(--grey-030, #fafafa);
  border-radius: 5px;
  height: 28.48px;
}

.outline-autocomplete-field .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

.outline-autocomplete-field .mat-form-field-wrapper .mat-form-field-infix {
  padding: 0.36rem 0.75em !important;
  margin: 0 !important;
  border: none !important;
  line-height: 1rem !important;
}

.outline-autocomplete-field .mat-form-field-wrapper .mat-form-field-underline {
  display: none !important;
}

.outline-autocomplete-field .mat-form-field-wrapper .mat-form-field-flex {
  border: 1px solid #dcdcdc !important;
  border-radius: 5px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.outline-autocomplete-field:hover .mat-form-field-wrapper .mat-form-field-flex {
  border: 2px solid #202020 !important;
}
.outline-autocomplete-field:hover .mat-form-field-wrapper .mat-form-field-infix {
  padding: calc(0.36rem - 1px) calc(0.75em - 1px) !important;
}

.outline-autocomplete-field.mat-focused .mat-form-field-wrapper .mat-form-field-flex {
  border: 2px solid var(--primary-500) !important;
}
.outline-autocomplete-field.mat-focused .mat-form-field-wrapper .mat-form-field-infix {
  padding: calc(0.36rem - 1px) calc(0.75em - 1px) !important;
}
