@media screen {
  .show-on-print,
  .print-area-container {
    display: none !important;
  }
  .visible-on-print {
    visibility: hidden !important;
    height: 0px;
    overflow: hidden;
  }
}

@media print {
  .hide-on-print {
    display: none !important;
  }

  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }

  .content-block {
    page-break-inside: avoid !important;
  }

  table.paging thead td,
  table.paging tfoot td {
    height: 0.5in;
  }

  footer {
    width: 100%;

    position: fixed;
    bottom: 0;
  }

  .cdk-global-scrollblock {
    position: initial;
  }

  .mat-expansion-panel {
    break-inside: avoid;
  }

  html,
  body,
  app-root {
    overflow: visible;
    background-color: white;
    margin: 0px;
  }

  .hide-on-print,
  .mat-tab-list,
  app-navigation-bar,
  app-footer,
  .mat-form-field,
  locations_selected,
  button,
  .action-buttons,
  .labels,
  .mat-expansion-indicator,
  div#hubspot-messages-iframe-container,
  .cdk-overlay-container {
    display: none !important;
  }

  .mat-expansion-panel-body {
    padding: 0px !important;
  }

  .visible-on-print {
    visibility: visible !important;
    height: auto;
    overflow: auto;
  }

  mat-expansion-panel-header,
  .mat-expansion-panel-header {
    padding: 10px 0px 0px 0px !important;
    font-weight: bold !important;
    height: 25px !important;
  }

  .mat-expansion-panel-content {
    visibility: unset !important;
    height: unset !important;
  }

  .empty-section-panel {
    display: none !important;
  }

  menu-dish {
    padding: 5px 0px !important;
  }

  menu {
    border-radius: 0;
    line-height: 1.3em;
  }

  body.print-template-landscape menu {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
    padding: 0 15px !important;
  }
  body.print-template-portrait menu {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    text-align: center;
    padding: 15px 0 !important;
    margin: 0 40px !important;
  }

  .dashboard-print-wrapper {
    padding: 2rem !important;
    border-radius: 0.5rem;
  }

  .grid-container {
    border-radius: 0.5rem;
  }
}

.print-area-container {
  position: relative;
  display: block;
  float: none;
}
.print-page {
  position: relative;
  display: block;
  float: none;
  page-break-inside: avoid;
  border: 1px solid transparent;
}
.print-page-break {
  clear: both;
  page-break-before: always;
}
.print-page-footer-container {
  width: 100%;
  position: fixed;
  bottom: 0rem;
  text-align: center;
}
.print-page-footer-label {
  color: var(--primary-400);
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: var(--SizeNormal);
  align-items: start;
  gap: 0.5rem;
}

.print-page-footer-logo {
  max-height: 15px;
}

.dish-print-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(200px, calc(100% / 2 - 5%)), 1fr));
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 16px;
  justify-content: stretch;
  align-content: stretch;
  grid-auto-flow: dense;
  width: 100%;
  padding: 10%;
}

.ingredients-print-container {
  width: 100%;
  padding: 10%;
}

.print-size {
  font-size: 16px !important;
}

.print-size-portait {
  width: 792px !important;
}

.print-size-landscape {
  width: 1120px !important;
}
