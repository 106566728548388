.portion-table-container {
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
}

.portion-table-container table,
.portion-table,
.ingredient-table,
.sub-recipe-table {
  text-align: center;
  color: var(--gray-900);
  font-size: 0.8rem !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.ingredient-table,
.sub-recipe-table {
  width: 100%;
}

.portion-table-container table td,
.portion-table-container table th,
.ingredient-table td,
.ingredient-table th,
.sub-recipe-table td,
.sub-recipe-table th,
.portion-table-container .standard-cell-padding,
.ingredient-table .standard-cell-padding,
.sub-recipe-table .standard-cell-padding {
  padding: 5px 0.5rem;
}

.portion-table-container .standard-cell-color {
  background-color: var(--white);
}
.portion-table-container .no-cell-padding {
  padding: 0px !important;
}

.table-cell-grey {
  background-color: var(--table-300);
}
.table-cell-light-grey {
  background-color: var(--table-200);
}

.table-cell-bordered-top {
  border-top: 1px solid var(--table-200);
}
.table-cell-bordered-right {
  border-right: 1px solid var(--table-200);
}
.table-cell-bordered-bottom {
  border-bottom: 1px solid var(--table-200);
}
.table-cell-bordered-left {
  border-left: 1px solid var(--table-200);
}

.table-cell-top-left {
  border-top-left-radius: 0.5rem;
}
.table-cell-top-right {
  border-top-right-radius: 0.5rem;
}
.table-cell-bottom-right {
  border-bottom-right-radius: 0.5rem;
}
.table-cell-bottom-left {
  border-bottom-left-radius: 0.5rem;
}

.portion-table-container .scroll-control-cell {
  position: relative;
  height: 32px !important;
}

.portion-table-container .scroll-control-container {
  width: calc(100% + 45px);
  position: absolute;
  left: -45px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 1.2rem !important;
}

.portion-table-container .scroll-control-container button {
  font-size: 1rem;
  padding: 0.4rem;
  height: fit-content;
}

.portion-table-container .scroll-shadow-left {
  box-shadow: 0px 0px 7px 0px var(--gray-500);
  clip-path: inset(0px 0px 0px -15px);
  padding: 0.1px !important;
}
.portion-table-container .scroll-shadow-right {
  box-shadow: 0px 0px 7px 0px var(--gray-500);
  clip-path: inset(0px -15px 0px 0px);
  padding: 0.1px !important;
}
.portion-table-container .no-scroll-padding {
  padding: 0px !important;
  background-color: transparent !important;
}

.portion-table-container .scroll-column-cell-left,
.portion-table-container .scroll-column-cell-right {
  width: 0px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  background-color: transparent !important;
}

.portion-table-container .fitted-column,
.ingredient-table .fitted-column,
.sub-recipe-table .fitted-column {
  width: 0px;
}

.portion-table-container .table-cell-invisible {
  background-color: var(--white) !important;
}

.mat-sort-header-arrow {
  color: #fff !important;
}

.ingredient-unit-cell {
  padding-left: 0.75rem;
  vertical-align: middle;
  text-align: center;
}
