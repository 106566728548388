.mat-badge-content {
  text-align: center;
  border-radius: 20px !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  font-weight: 600;
}

.text-badge .mat-badge-content {
  display: grid;
  width: auto;
  height: 18px;
  align-items: center;
  padding: 0px 0.55rem;
  top: -8px !important;
  left: 101% !important;
  right: auto !important;
  font-size: 0.5rem;
  line-height: 0px;
}

.mat-badge-hidden .mat-badge-content {
  display: none !important;
}
