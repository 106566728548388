@import '@angular/material/theming';
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");

@include mat-core();

$myrtle-green-palette: (
 50: #DEF3F0,
 100: #9DDAD2,
 200: #5CC2B5,
 300: #41AFA1,
 400: #358F83,
 500: #296E65,
 600: #246058,
 700: #1F534C,
 800: #1A453F,
 900: #153733,
 contrast: (
   50: black,
   100: black,
   200: black,
   300: black,
   400: white,
   500: white,
   600: white,
   700: white,
   800: white,
   900: white
 )
);
$cinnabar-red-palette: (
  50: #FCE9E8,
  100: #F9D2D1,
  200: #F5BCBA,
  300: #EF8F8B,
  400: #E8625D,
  500: #E54B46,
  600: #E02B25,
  700: #C5211C,
  800: #A41B17,
  900: #831612,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);
$japanese-indigo-blue-palette: (
  50: #D9F1F3,
  100: #B3E2E7,
  200: #40B8C3,
  300: #32969F,
  400: #267279,
  500: #1A4E53,
  600: #174449,
  700: #143B3E,
  800: #103134,
  900: #0D272A,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);


$custom-typography: mat-typography-config(
  $font-family: 'Montserrat'
);
@include angular-material-typography($custom-typography);

$custom-theme-primary: mat-palette($myrtle-green-palette, 500);
$custom-theme-accent:mat-palette($japanese-indigo-blue-palette, 500);
$custom-theme-warn: mat-palette($cinnabar-red-palette, 500);
$custom-theme: mat-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);
@include angular-material-theme($custom-theme);