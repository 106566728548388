.theme-foodop {
  --primary-50: #def3f0;
  --primary-100: #9ddad2;
  --primary-200: #5cc2b5;
  --primary-300: #41afa1;
  --primary-400: #358f83;
  --primary-500: #296e65;
  --primary-600: #246058;
  --primary-700: #1f534c;
  --primary-800: #1a453f;
  --primary-900: #153733;

  --secondary-50: #d9f1f3;
  --secondary-100: #b3e2e7;
  --secondary-200: #40b8c3;
  --secondary-300: #32969f;
  --secondary-400: #267279;
  --secondary-500: #1a4e53;
  --secondary-600: #174449;
  --secondary-700: #143b3e;
  --secondary-800: #103134;
  --secondary-900: #0d272a;

  --accent-1: rgb(81, 202, 162);
  --accent-2: rgb(66, 145, 177);
  --accent-3: rgb(227, 65, 86);
  --accent-4: rgb(235, 239, 237);
  --accent-5: rgb(1 154 126);

  --shade-50: #f9f9f9;
  --shade-100: #f2f9f7;
  --shade-200: #ddebe7;
  --shade-300: #fef7e6a1;

  --graph-1: #358f83;
  --graph-2: #296e65;
  --graph-3: #246058;
  --graph-4: #1f534c;
  --graph-5: #1a453f;
  --graph-6: #153733;
  --graph-7: #0d272a;
  --graph-8: #103134;
  --graph-9: #143b3e;
  --graph-10: #174449;
  --graph-11: #1a4e53;
  --graph-12: #267279;
  --graph-13: #32969f;
  --graph-14: #40b8c3;
  --graph-15: #b3e2e7;
  --graph-16: #9ddad2;
  --graph-17: #5cc2b5;
  --graph-18: #41afa1;

  --green-50: #e5f4e8;
  --green-100: #cbe9d0;
  --green-200: #b1deb9;
  --green-300: #7cc98a;
  --green-400: #4ab15d;
  --green-500: #3f974f;
  --green-600: #378445;
  --green-700: #2f713b;
  --green-800: #275e31;
  --green-900: #204c28;

  --yellow-50: #fef7e6;
  --yellow-100: #fef0cd;
  --yellow-200: #fde8b4;
  --yellow-300: #fbd883;
  --yellow-400: #fac951;
  --yellow-500: #f9c138;
  --yellow-600: #f8b513;
  --yellow-700: #dea007;
  --yellow-800: #b98506;
  --yellow-900: #946a04;

  --red-50: #fce9e8;
  --red-100: #f9d2d1;
  --red-200: #f5bcba;
  --red-300: #ef8f8b;
  --red-400: #e8625d;
  --red-500: #e54b46;
  --red-600: #e02b25;
  --red-700: #c5211c;
  --red-800: #a41b17;
  --red-900: #831612;

  --gray-50: #efefef;
  --gray-100: #dfdfdf;
  --gray-200: #cfcfcf;
  --gray-300: #c0c0c0;
  --gray-400: #b0b0b0;
  --gray-500: #808080;
  --gray-600: #404040;
  --gray-700: #303030;
  --gray-800: #202020;
  --gray-900: #101010;

  --white: #ffffff;

  --GreyScale1: rgb(0, 0, 0);
  --GreyScale2: rgb(70, 70, 70);
  --GreyScale3: rgb(20, 6, 6);
  --GreyScale4: rgb(222, 222, 222);
  --GreyScale5: rgb(255, 255, 255);
  --GreyScale6: rgb(248, 248, 248);
  --GreyScale7: rgb(27, 24, 24);

  --ColorScale1: rgb(0, 58, 64);
  --ColorScale2: rgb(27, 92, 84);
  --ColorScale3: rgb(36, 112, 103);
  --ColorScale4: rgb(184, 201, 199);
  --ColorScale5: rgb(132, 169, 163);
  --ColorScale6: rgb(189, 236, 229);
  --ColorScale7: rgb(205, 239, 224);
  --ColorScale8: rgb(240, 247, 244);
  --ColorScale9: rgb(98, 138, 133);

  --chip-text: rgb(36, 112, 103);
  --chip-bg: rgb(240, 247, 244);

  --primary-text: rgb(36, 112, 103);
  --text-hover: #153733;
  --drop-area: #ebf2f0;

  --procurement-header: #d2d2d2;
  --procurement-light: #edf2f0;
  --procurement-dark: #c3d7d1;

  --action-button: #296e65;
  --action-button-hover: #1f534c;

  --neutral-button: var(--table-300);
  --neutral-button-hover: var(--table-200);

  --font-primary: 'Montserrat';

  --SizeExtraTiny: 0.1rem;
  --SizeTiny: 0.25rem;
  --SizeMicro: 0.4rem;
  --SizeMini: 0.5rem;
  --SizeSmall: 0.75rem;
  --SizeNormal: 0.8rem;
  --SizeMedium: 1rem;
  --SizeLarge: 1.25rem;
  --SizeExtraLarge: 1.5rem;

  --PopUp1Width: calc(100vw - 80px);
  --PopUp2Width: 80vw;
  --PopUp3Width: 75vw;
  --PopUp4Width: 70vw;

  --PopUp1Height: 85vh;
  --PopUp2Height: 80vh;
  --PopUp3Height: 75vh;
  --PopUp4Height: 70vh;

  --button-roundness: 50px;

  --table-300: #ddebe7;
  --table-200: #f2f9f7;
  --table-100: #f9f9f9;

  --blue-50: #edf8ff;
  --blue-100: #28b1f2;

  --widget: var(--primary-500);
}

.theme-dagrofa {
  --primary-50: #def3f0;
  --primary-100: #9ddad2;
  --primary-200: #5cc2b5;
  --primary-300: #41afa1;
  --primary-400: #358f83;
  --primary-500: black;
  --primary-600: black;
  --primary-700: #1f534c;
  --primary-800: #1a453f;
  --primary-900: #153733;

  --secondary-50: #d9f1f3;
  --secondary-100: #b3e2e7;
  --secondary-200: #40b8c3;
  --secondary-300: #32969f;
  --secondary-400: #267279;
  --secondary-500: #1a4e53;
  --secondary-600: #174449;
  --secondary-700: #143b3e;
  --secondary-800: #103134;
  --secondary-900: #0d272a;

  --accent-1: rgb(81, 202, 162);
  --accent-2: rgb(66, 145, 177);
  --accent-3: rgb(227, 65, 86);
  --accent-4: rgb(235, 239, 237);
  --accent-5: rgb(1 154 126);

  --shade-50: #f8f7f4;
  --shade-100: #edebe5;
  --shade-200: #d3cec3;

  --graph-1: #b4afa7;
  --graph-2: #a39e96;
  --graph-3: #938e86;
  --graph-4: #827d75;
  --graph-5: #726d66;
  --graph-6: #625e58;
  --graph-7: #534f4a;
  --graph-8: #45423e;
  --graph-9: #373533;
  --graph-10: #2a2827;
  --graph-11: #1d1c1b;
  --graph-12: #d6d2c9;
  --graph-13: #dedbd3;
  --graph-14: #e5e3de;
  --graph-15: #ebeae6;
  --graph-16: #f1f0ed;
  --graph-17: #f6f5f3;
  --graph-18: #fbfbfa;

  --green-50: #e5f4e8;
  --green-100: #cbe9d0;
  --green-200: #b1deb9;
  --green-300: #7cc98a;
  --green-400: #4ab15d;
  --green-500: #3f974f;
  --green-600: #378445;
  --green-700: #2f713b;
  --green-800: #275e31;
  --green-900: #204c28;

  --yellow-50: #fef7e6;
  --yellow-100: #fef0cd;
  --yellow-200: #fde8b4;
  --yellow-300: #fbd883;
  --yellow-400: #fac951;
  --yellow-500: #f9c138;
  --yellow-600: #f8b513;
  --yellow-700: #dea007;
  --yellow-800: #b98506;
  --yellow-900: #946a04;

  --red-50: #fce9e8;
  --red-100: #f9d2d1;
  --red-200: #f5bcba;
  --red-300: #ef8f8b;
  --red-400: #e8625d;
  --red-500: #e54b46;
  --red-600: #e02b25;
  --red-700: #c5211c;
  --red-800: #a41b17;
  --red-900: #831612;

  --gray-50: #edebe6;
  --gray-100: #d3cec3;
  --gray-200: #cfcfcf;
  --gray-300: #c0c0c0;
  --gray-400: #b4afa7;
  --gray-500: #938e86;
  --gray-600: #625e58;
  --gray-700: #534f4a;
  --gray-800: #373533;
  --gray-900: #101010;

  --white: #ffffff;

  --GreyScale1: rgb(0, 0, 0);
  --GreyScale2: rgb(70, 70, 70);
  --GreyScale3: rgb(20, 6, 6);
  --GreyScale4: rgb(222, 222, 222);
  --GreyScale5: rgb(0, 0, 0);
  --GreyScale6: rgb(248, 248, 248);
  --GreyScale7: rgb(27, 24, 24);

  --ColorScale1: rgb(0, 58, 64);
  --ColorScale2: rgb(0, 0, 0);
  --ColorScale3: #d3cec3;
  --ColorScale4: rgb(184, 201, 199);
  --ColorScale5: rgb(132, 169, 163);
  --ColorScale6: rgb(189, 236, 229);
  --ColorScale7: rgb(205, 239, 224);
  --ColorScale8: #f8f7f4;
  --ColorScale9: #d3cec3;

  --chip-text: rgb(0, 0, 0);
  --chip-bg: #f8f7f4;

  --primary-text: black;
  --text-hover: #d73536;
  --drop-area: #edebe6;

  --procurement-header: #d3cec3;
  --procurement-light: #edebe6;
  --procurement-dark: #d3cec3;

  --action-button: #d73536;
  --action-button-hover: #a12d37;

  --neutral-button: var(--table-300);
  --neutral-button-hover: var(--table-200);

  --font-primary: 'Mulish';

  --SizeExtraTiny: 0.1rem;
  --SizeTiny: 0.25rem;
  --SizeMicro: 0.4rem;
  --SizeMini: 0.5rem;
  --SizeSmall: 0.75rem;
  --SizeNormal: 0.8rem;
  --SizeMedium: 1rem;
  --SizeLarge: 1.25rem;
  --SizeExtraLarge: 1.5rem;

  --PopUp1Width: calc(100vw - 80px);
  --PopUp2Width: 80vw;
  --PopUp3Width: 75vw;
  --PopUp4Width: 70vw;

  --PopUp1Height: 85vh;
  --PopUp2Height: 80vh;
  --PopUp3Height: 75vh;
  --PopUp4Height: 70vh;

  --button-roundness: 6px;

  --table-300: #d3cec3;
  --table-200: #edebe5;
  --table-100: #f8f7f4;

  --blue-50: #edf8ff;
  --blue-100: #28b1f2;

  --widget: #d3cec3;
}

.button-rounded {
  border-radius: var(--button-roundness);
}

body,
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
app-root {
  font-size: 16px;
  color: var(--primary-500);
  background-color: var(--white);
  min-height: 100vh;
  height: 100%;
  display: block;
}

.pop-up-1 {
  width: var(--PopUp1Width);
  max-width: var(--PopUp1Width);
  min-height: 365px;
  max-height: var(--PopUp1Height);
}
.pop-up-2 {
  width: var(--PopUp2Width);
  max-width: var(--PopUp2Width);
  min-height: 365px;
  max-height: var(--PopUp2Height);
}

.ingredient-pop-up {
  width: 50vw;
  min-width: 875px;
  min-height: 365px;
  max-height: var(--PopUp2Height);
}

.pop-up-2-flexible {
  min-width: 50vw;
  max-width: var(--PopUp2Width);
  min-height: 365px;
  max-height: var(--PopUp2Height);
}
.pop-up-3 {
  width: var(--PopUp3Width);
  max-width: var(--PopUp3Width);
  min-height: 365px;
  max-height: var(--PopUp3Height);
}
.pop-up-centered {
  align-items: center;
  justify-content: center;
}

.shrinkage-view {
  background-color: var(--white);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 0.5rem 1rem;
}

h3 {
  font-size: var(--SizeLarge) !important;
  color: var(--primary-500) !important;
  margin: 0 !important;
}

a,
sub {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: transparent;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input:focus {
  outline-color: var(--primary-500);
}

.l-footer {
  border-top: 1px solid var(--GreyScale2);
  clear: both;
  position: relative;
  height: 100px;
  margin-top: 50px;
  display: inline-grid;
  width: 90%;
  margin-left: 5%;
}

.l-min-body-height {
  min-height: calc(100% - 75px - 150px - 1rem);
}

menu-overview-week {
  display: block;
  overflow: scroll;
  padding-bottom: 1.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
menu-overview-week::-webkit-scrollbar {
  display: none;
}

menu-overview-section {
  /* display: block;
    padding: 1rem; */
  font-weight: bold;
  font-size: 1rem;
}

menu-dish {
  display: block;
  padding: 0.5rem 0rem;
  cursor: pointer;
  height: 100%;
  font-weight: normal;
  font-size: 0.8rem;
}

menu {
  scroll-snap-align: start;
}

menu .mat-expansion-button > span {
  height: 100%;
}

menu .mat-expansion-button {
  padding: 0px !important;
}

tracking-dish-picker {
  /* display: flex;
    flex-direction: column;
    height: 100%; */
}

display {
  width: 100%;
  position: relative;
  display: block;
  border-radius: 0.2rem 0.2rem 0.5rem 0.3rem;
  background-color: var(--GreyScale7);
  padding: 6% 6% 7% 5%;
  overflow: auto;
  box-shadow: 4px 3px 1px -2px rgb(0 0 0 / 90%), 3px 4px 4px 0px rgb(0 0 0 / 70%), 0px 1px 5px 0px rgb(0 0 0 / 12%), inset -11px -9px 2px -6px rgb(0 0 0 / 100%);
  color: var(--GreyScale1);
}

.view-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  justify-content: stretch;
  align-content: stretch;
  grid-auto-flow: dense;
}

.l-no-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.animated-width {
  transition: width 500ms;
}

.neutral-button {
  border: var(--neutral-button) 1px solid !important;
  border-radius: var(--button-roundness) !important;
  cursor: pointer !important;
  color: var(--gray-900) !important;
  background-color: var(--neutral-button) !important;
  width: fit-content !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  line-height: unset !important;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

.neutral-button label {
  cursor: pointer !important;
  color: var(--gray-900) !important;
  font-size: 1rem !important;
  line-height: unset !important;
}

.neutral-button:hover {
  border: var(--neutral-button-hover) 1px solid !important;
  background-color: var(--neutral-button-hover) !important;
}

.neutral-button-small {
  border: var(--neutral-button) 1px solid !important;
  border-radius: var(--button-roundness) !important;
  cursor: pointer !important;
  color: var(--gray-900) !important;
  background-color: var(--neutral-button) !important;
  width: fit-content !important;
  height: fit-content !important;
  padding: 0.3rem 0.75rem !important;
  font-size: 0.8rem !important;
  line-height: unset !important;
}

.action-button {
  border: var(--action-button) 1px solid !important;
  border-radius: var(--button-roundness) !important;
  cursor: pointer !important;
  color: var(--ColorScale8) !important;
  background-color: var(--action-button) !important;
  width: fit-content !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  line-height: unset !important;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;
}

.action-button label {
  cursor: pointer !important;
  color: var(--ColorScale8) !important;
  font-size: 1rem !important;
  line-height: unset !important;
}

.action-button:hover {
  border: var(--action-button-hover) 1px solid !important;
  background-color: var(--action-button-hover) !important;
}

.action-button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.action-button-small {
  border: var(--action-button) 1px solid;
  border-radius: var(--button-roundness);
  cursor: pointer;
  color: var(--ColorScale8);
  background-color: var(--action-button);
  width: fit-content;
  padding: 0.3rem 0.75rem;
  font-size: 0.8rem;
  line-height: unset;
}
.action-button-small:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.primary-button {
  border: var(--ColorScale2) 1px solid !important;
  border-radius: var(--button-roundness) !important;
  cursor: pointer !important;
  color: var(--ColorScale8) !important;
  background-color: var(--ColorScale2) !important;
  width: fit-content !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  line-height: unset !important;
}

.primary-button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.primary-button label {
  cursor: pointer !important;
  color: var(--ColorScale8) !important;
  font-size: 1rem !important;
  line-height: unset !important;
}

.secondary-button {
  border: var(--ColorScale2) 1px solid !important;
  border-radius: var(--button-roundness) !important;
  cursor: pointer !important;
  color: var(--ColorScale2) !important;
  width: fit-content !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  line-height: unset !important;
}

.secondary-button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.secondary-button label {
  cursor: pointer !important;
  color: var(--ColorScale2) !important;
  font-size: 1rem !important;
  line-height: unset !important;
}

.primary-button mat-icon,
.secondary-button mat-icon {
  font-size: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
}

.secondary-button-small {
  border: var(--ColorScale2) 1px solid !important;
  border-radius: var(--button-roundness) !important;
  cursor: pointer !important;
  color: var(--ColorScale2) !important;
  width: fit-content !important;
  padding: 0.3rem 0.75rem !important;
  font-size: 0.8rem !important;
  line-height: unset !important;
}

.secondary-button-small:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.primary-button-small {
  border: var(--ColorScale2) 1px solid;
  border-radius: var(--button-roundness);
  cursor: pointer;
  color: var(--ColorScale8);
  background-color: var(--ColorScale2);
  width: fit-content;
  padding: 0.3rem 0.75rem;
  font-size: 0.8rem;
  line-height: unset;
}

.primary-button-small:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.mat-button-disabled:not(.mat-icon-button) {
  color: var(--white) !important;
  background-color: var(--gray-400) !important;
  border: var(--gray-400) 1px solid !important;
}

.l-white-background {
  background-color: var(--white);
}

/* Tables */

/*===================
    Print Layout
===================*/
.position-fixed {
  position: fixed;
}
/*===================
    Grid Layout
===================*/
.grid-col-span-1 {
  grid-column: span 1;
}
.grid-col-span-2 {
  grid-column: span 2;
}

.l-grid-item {
  border: 1px solid var(--ColorScale2);
  border-radius: 0.4rem;
  padding: 1rem;
}

.grid-col-1 {
  grid-column-start: 1;
}
.grid-col-2 {
  grid-column-start: 2;
}

.grid-row-span-1 {
  grid-row: span 1;
}
.grid-row-span-2 {
  grid-row: span 2;
}

/*===================
		Layout
===================*/
/*
 Positioning
------------*/

.l-overflow {
  overflow: auto;
}

.l-overflow-hidden {
  overflow: hidden;
}

.l-overflow-scroll {
  overflow: scroll;
}
.l-overflow-scroll-y {
  overflow-y: scroll;
}

.l-overflow-visible {
  overflow: visible;
}
.o-overflow-hidden {
  overflow: hidden !important;
}

.l-scroll-container-snap-x {
  /* Always force (mandatory) scrolling to a snap point on the x-axis */
  scroll-snap-type: x mandatory;
}

.l-scroll-snap-start {
  scroll-snap-align: start;
  scroll-margin: 0px 2px 0px 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.l-hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.l-hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.l-clear {
  clear: both;
}
.l-clear-right {
  clear: right;
}

.l-block {
  display: block;
}

.o-block {
  display: block !important;
}

.l-absolute-top-right-corner-1 {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.l-absolute-top-right-corner {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.l-absolute-top-right-indeded-micro {
  position: absolute;
  right: 0.5rem;
  top: 0rem;
}

.o-absolute-top-right-indeded-micro {
  position: absolute !important;
  right: 0.5rem !important;
  top: 0.5rem !important;
}

.l-absolute-bottom-right-corner {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

.o-absolute-bottom-right-corner {
  position: absolute !important;
  right: 2rem !important;
  bottom: 2rem !important;
}

.l-absolute-bottom-right {
  position: absolute;
  right: 0rem;
  bottom: -2rem;
}

.l-absolute-beneath-bottom {
  position: absolute;
  bottom: -3px;
}

.l-position-centered {
  align-content: center;
}

.l-position-relative {
  position: relative;
}

.l-position-absolute {
  position: absolute;
}

.l-position-right-0 {
  right: 0px;
}

.l-position-top-0 {
  top: 0px;
}
.l-position-bottom-0 {
  bottom: 0px;
}

.l-position-bottom-small {
  bottom: 0.5rem;
}

.l-position-vetical-mid {
  top: 50%;
}

.l-position-top--3 {
  top: -3px;
}

.l-position-top-3 {
  top: 3px;
}

.l-position-top-8 {
  top: 8px;
}

.l-position-top-10 {
  top: 10px;
}
.l-position-right-3 {
  right: 3px;
}

.l-position-top-10_45 {
  top: 10.45rem;
}

.l-position-right-1rem {
  right: 1rem;
}
.l-position-left-1rem {
  left: 1rem;
}

.l-position-left-dynamic {
  left: 0.5vw;
}

.l-position-left-28 {
  left: 28px;
}

.l-position-mid {
  left: 0;
  right: 0;
}

.l-position-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.l-position-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.l-align-items-center {
  align-items: center;
}

.l-position-center-vertically {
  align-items: center;
}

.l-position-right-flex {
  margin-left: auto;
  order: 2;
}

.l-inline-block {
  display: inline-block;
}

.o-inline-block {
  display: inline-block !important;
}

.l-flex-centered {
  align-items: center;
}

.l-z-index-2 {
  z-index: 2;
}
.l-z-index-100 {
  z-index: 100;
}
.l-display-grid-equal-rows {
  grid-auto-rows: 1fr;
  display: grid;
}

.l-display-flex {
  display: flex;
}
.o-display-flex {
  display: flex !important;
}
.l-display-inline-flex {
  display: inline-flex;
}
.l-flex-row {
  flex-direction: row;
}

.l-flex-column {
  flex-direction: column;
}

.l-flex-revert {
  flex-direction: revert;
}

.l-no-flex-wrap {
  flex-wrap: nowrap;
}
.l-flex-wrap {
  flex-wrap: wrap;
}

.l-space-between {
  justify-content: space-between;
}

.l-space-around {
  justify-content: space-around;
}

.l-flex-horizontally-center {
  justify-content: center;
}

.l-justify-content-center {
  justify-content: center;
}
.l-flex-shrink-0 {
  flex-shrink: 0;
}

.l-flex-start {
  align-content: flex-start;
}

.l-flex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.l-flex-left {
  justify-content: flex-start;
}
.l-flex-end {
  justify-content: flex-end;
}

.l-flex-grid-space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
}

.l-stretch {
  align-items: stretch;
}

.l-align-start {
  align-items: flex-start;
}

.l-align-end {
  align-items: flex-end;
}
.o-align-end {
  align-items: flex-end !important;
}

.l-flex-grow-1 {
  flex-grow: 1;
}

.l-flex-grow-2 {
  flex-grow: 2;
}

.l-flex-grow-3 {
  flex-grow: 3;
}
.l-flex-grow-1000 {
  flex-grow: 1000;
}

.l-center-top {
  margin: 0;
  position: absolute;
  top: 0px;
  left: 50%;
  padding: 0.2rem;
  transform: translate(-50%, 0%);
}

/* Float */
.l-float-left {
  float: left;
}

.l-float-right {
  float: right;
}

/* Size */

.l-width-fit {
  width: fit-content;
}

.o-width-auto {
  width: auto !important;
}

.l-width-0 {
  width: 0px;
}
.l-width-full {
  width: 100%;
}

.l-width-full-10 {
  width: -moz-calc(100% - 10px);
  width: -webkit-calc(100% - 10px);
  width: calc(100% - 10px);
}

.l-width-full-72-small {
  width: -moz-calc(100% - 72px - 0.4rem);
  width: -webkit-calc(100% - 72px - 0.4rem);
  width: calc(100% - 72px - 0.4rem);
}

.l-min-width-1 {
  min-width: 1px;
}
.l-min-width-15 {
  min-width: 15px;
}

.o-min-width-36 {
  min-width: 36px !important;
}

.l-width-half {
  width: 50%;
}

.l-width-half-small-margin {
  width: calc(50% - 0.6rem);
}
.l-width-2p {
  width: 2%;
}

.l-width-quarter {
  width: 25%;
}

.l-max-width-quarter {
  max-width: 25%;
}

.l-width-20p {
  width: 20%;
}
.l-min-width-20p {
  min-width: 20%;
}

.l-min-width-25p {
  min-width: 25%;
}
.l-width-30p {
  width: 30%;
}

.l-width-32p {
  width: 32%;
}

.l-width-35p {
  width: 35%;
}

.l-width-40p {
  width: 40%;
}

.l-width-45p {
  width: 45%;
}

.l-width-48p {
  width: 48%;
}
.l-width-60p {
  width: 60%;
}

.l-min-width-65 {
  min-width: 65px;
}

.l-min-width-50p {
  min-width: 50%;
}

.l-width-65p {
  width: 65%;
}
.l-max-width-65p {
  min-width: 70%;
}

.l-width-70p {
  width: 70%;
}

.l-min-width-70p {
  min-width: 70%;
}
.l-width-80p {
  width: 80%;
}
.l-min-width-80p {
  min-width: 80%;
}

.l-width-85p {
  width: 85%;
}
.l-width-90p {
  width: 90%;
}
.l-width-95p {
  width: 95%;
}

.l-width-20 {
  width: 20px;
}

.o-width-medium {
  width: 1rem !important;
}

.o-width-200 {
  width: 200px !important;
}

.o-width-150 {
  width: 150px;
}
.o-width-180 {
  width: 180px;
}

.l-width-300 {
  width: 300px;
}
.l-min-width-300 {
  min-width: 300px;
}

.l-width-400 {
  width: 400px;
}

.l-min-width-400 {
  min-width: 400px;
}

.l-width-36 {
  width: 36px;
}

.l-width-50 {
  width: 50px;
}

.l-width-100 {
  width: 100px;
}
.l-width-150 {
  width: 150px;
}

.l-max-width-1 {
  max-width: 1px;
}
.l-max-width-15p {
  max-width: 15%;
}

.l-max-width-25p {
  max-width: 25%;
}

.l-max-width-50p {
  max-width: 50%;
}

.l-max-width-70p {
  max-width: 70%;
}

.l-max-width-80p {
  max-width: 80%;
}

.l-min-width-900 {
  min-width: 900px;
}

.l-min-width-1200 {
  min-width: 1200px;
}
.l-max-width-32p {
  max-width: 32%;
}

.l-max-width-300 {
  max-width: 300px;
}

.l-max-width-200 {
  max-width: 200px;
}

.l-max-width-50 {
  max-width: 50px;
}

.l-min-width-50 {
  min-width: 50px;
}
.l-min-width-100 {
  min-width: 100px;
}
.o-min-width-80 {
  min-width: 80px !important;
}
.l-min-width-150 {
  min-width: 150px;
}

.l-min-width-200 {
  min-width: 200px;
}

.l-min-width-350 {
  min-width: 350px;
}

.l-min-width-450 {
  min-width: 450px;
}

.l-min-width-480 {
  min-width: 480px;
}
.l-min-width-500 {
  min-width: 500px;
}

.l-min-width-50p {
  min-width: 50%;
}
.l-height-auto {
  height: auto;
}

.o-height-auto {
  height: auto !important;
}

.l-height-1rem {
  height: 1rem;
}
.l-height-fit {
  height: fit-content;
}

.l-height-80vh {
  height: 80vh;
}
.l-height-0 {
  height: 0px;
}
.l-height-1 {
  height: 1px;
}
.o-height-small {
  height: 0.7rem !important;
}
.o-height-medium {
  height: 1rem !important;
}

.l-height-3 {
  height: 3px;
}
.l-height-20 {
  height: 20px;
}
.l-height-30 {
  height: 30px;
}
.l-height-40 {
  height: 40px;
}
.l-height-45 {
  height: 45px;
}
.l-height-50 {
  height: 50px;
}
.l-height-60 {
  height: 60px;
}
.l-height-150 {
  height: 150px;
}
.l-min-height-20 {
  min-height: 20px;
}
.l-min-height-30 {
  min-height: 30px;
}

.l-min-height-70 {
  min-height: 70px;
}
.l-min-height-100 {
  min-height: 100px;
}
.l-min-height-250 {
  min-height: 250px;
}
.l-min-height-365 {
  min-height: 365px;
}

.l-height-50p {
  height: 50%;
}
.l-height-70p {
  height: 70%;
}

.l-height-extra-large {
  height: 2rem;
}

.l-height-full {
  height: 100%;
}
.l-height-full-vh {
  height: 100vh;
}
.l-height-80p {
  height: 80%;
}

.l-max-height-80p {
  max-height: 80%;
}

.l-max-height-70p {
  max-height: 70%;
}

.l-max-height-100 {
  max-height: 100px;
}

.l-max-height-300 {
  max-height: 300px;
}

.l-height-full-10 {
  height: -moz-calc(100% - 10px);
  height: -webkit-calc(100% - 10px);
  height: calc(100% - 10px);
}

.l-aspect-16-9 {
  aspect-ratio: 16 / 9;
}
.l-aspect-416-240 {
  aspect-ratio: 416 / 240;
}

.l-aspect-a4 {
  aspect-ratio: 1/1.294;
}

.l-max-dimension-150 {
  max-width: 150px;
  max-height: 150px;
}

/* Margin */

.l-margin-auto {
  margin: auto;
}
.l-margin-left-auto {
  margin-left: auto;
}
.l-margin-right-auto {
  margin-right: auto;
}

.l-margin-vertical-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.l-margin-small {
  margin: 0.6rem;
}
.l-margin-medium {
  margin: 0.8rem;
}

.l-margin-top-tiny {
  margin-top: 0.2rem;
}
.l-margin-top-micro {
  margin-top: 0.4rem;
}
.l-margin-top-small {
  margin-top: 0.6rem;
}
.l-topmargin-medium {
  margin-top: 0.8rem;
}
.l-topmargin-large {
  margin-top: 1rem;
}

.l-rightmargin-ultra-tiny {
  margin-right: 0.1rem;
}
.l-rightmargin-tiny {
  margin-right: 0.2rem;
}
.l-margin-right-small {
  margin-right: 0.6rem;
}
.l-rightmargin-large {
  margin-right: 1rem;
}
.l-rightmargin-extra-large {
  margin-right: 1.5rem;
}
.o-rightmargin-large {
  margin-right: 1rem !important;
}

.l-bottommargin-ultra-tiny {
  margin-bottom: 0.1rem;
}
.l-bottommargin-micro {
  margin-bottom: 0.4rem;
}
.l-bottommargin-small {
  margin-bottom: 0.6rem;
}
.l-bottommargin-medium {
  margin-bottom: 0.8rem;
}
.l-bottommargin-large {
  margin-bottom: 1rem;
}
.l-bottommargin-extra-large {
  margin-bottom: 1.5rem;
}
.l-bottommargin-ultra-large {
  margin-bottom: 2rem;
}

.l-leftmargin-ultra-tiny {
  margin-left: 0.1rem;
}
.l-leftmargin-tiny {
  margin-left: 0.2rem;
}
.o-leftmargin-mini {
  margin-left: 0.5rem !important;
}
.l-leftmargin-mini {
  margin-left: 0.4rem;
}
.l-leftmargin-small {
  margin-left: 0.6rem;
}
.l-leftmargin-medium {
  margin-left: 0.8rem;
}
.l-leftmargin-large {
  margin-left: 1rem;
}
.l-leftmargin-extra-large {
  margin-left: 1.5rem;
}

.o-margin-top-0 {
  margin-top: 0px !important;
}
.o-margin-top-small {
  margin-top: 0.6rem !important;
}
.o-margin-top-large {
  margin-top: 1rem !important;
}
.o-bottommargin-small {
  margin-bottom: 0.6rem !important;
}
.o-bottommargin-large {
  margin-bottom: 1rem !important;
}
.o-rightmargin-tiny {
  margin-right: 0.2rem !important;
}

.l-margin-horizontal-small {
  margin-right: 0.6rem;
  margin-left: 0.6rem;
}
.l-margin-horizontal-medium {
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}
.l-margin-horizontal-large {
  margin-right: 1rem;
  margin-left: 1rem;
}
.l-margin-horizontal-extra-large {
  margin-right: 1.4rem;
  margin-left: 1.4rem;
}

.l-margin-vertical-tiny {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.l-margin-vertical-small {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.l-margin-vertical-medium {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.l-margin-vertical-large {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.o-margin-horizontal-small {
  margin-right: 0.2rem !important;
  margin-left: 0.2rem !important;
}

.o-margin-horizontal-medium {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.o-margin-vertical-small {
  margin-top: 0.2rem !important;
  margin-right: 0.2rem !important;
}

.l-margin-3px {
  margin: 3px;
}
.l-topmargin-3 {
  margin-top: 3px;
}
.l-topmargin-5 {
  margin-top: 5px;
}
.l-rightmargin-large-p {
  margin-right: 5%;
}

.l-bottommargin-3 {
  margin-bottom: 3px;
}
.l-bottommargin-7 {
  margin-bottom: 7px;
}

.l-margin-horizontal-5p {
  margin-left: 5%;
  margin-right: 5%;
}

.l-margin-horizontal-3rem {
  margin-left: 3rem;
  margin-right: 3rem;
}
.l-margin-horizontal-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.o-margin-top--20 {
  margin-top: -20px !important;
}

/* Padding */
.l-padding-none {
  padding: 0px;
}
.o-padding-none {
  padding: 0px !important;
}
.l-padding-micro {
  padding: 0.3rem;
}
.l-padding-mini {
  padding: 0.5rem;
}
.l-padding-small {
  padding: 0.6rem;
}
.l-padding-medium {
  padding: 0.8rem;
}
.l-padding-large {
  padding: 1rem;
}
.l-padding-extra-large {
  padding: 1.5rem;
}
.l-padding-5p {
  padding: 5%;
}

.l-padding-large-dynamic {
  padding: 1vw;
}
.l-padding-extra-large-dynamic {
  padding: 1.5vw;
}

.o-padding-small {
  padding: 0.6rem !important;
}

.l-padding-top-micro {
  padding-top: 0.3rem;
}
.l-padding-top-small {
  padding-top: 0.6rem;
}
.l-padding-top-medium {
  padding-top: 0.8rem;
}
.l-padding-top-large {
  padding-top: 1rem;
}
.l-padding-top-3p {
  padding-top: 3%;
}

.l-padding-top-5p {
  padding-top: 5%;
}

.l-padding-right-medium {
  padding-right: 0.8rem;
}
.l-padding-right-large {
  padding-right: 1rem;
}
.l-padding-bottom-tiny {
  padding-bottom: 0.2rem;
}
.l-padding-bottom-medium {
  padding-bottom: 0.5rem;
}
.l-padding-bottom-large {
  padding-bottom: 0.6rem;
}
.l-padding-bottom-extra-large {
  padding-bottom: 1rem;
}

.l-padding-left-none {
  padding-left: 0rem;
}
.l-padding-left-micro {
  padding-left: 0.3rem;
}
.l-padding-left-small {
  padding-left: 0.6rem;
}
.l-padding-left-large {
  padding-left: 1rem;
}

.l-padding-vertical-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.l-padding-vertical-micro {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.l-padding-vertical-mini {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.l-padding-vertical-small {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.l-padding-vertical-medium-dynamic {
  padding-top: 0.8vw;
  padding-bottom: 0.8vw;
}

.l-padding-vertical-large {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.l-padding-vertical-extra-large {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.l-padding-horizontal-tiny {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.l-padding-horizontal-medium {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.l-padding-horizontal-large-dynamic {
  padding-left: 1vw;
  padding-right: 1vw;
}
.l-padding-horizontal-large {
  padding-left: 1rem;
  padding-right: 1rem;
}
.l-padding-horizontal-semi-large {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.l-padding-horizontal-extra-large {
  padding-left: 3rem;
  padding-right: 3rem;
}

.o-padding-large {
  padding: 1rem !important;
}
.o-padding-right-medium {
  padding-right: 0.5rem !important;
}
.o-padding-right-extra-large {
  padding-right: 1.5rem !important;
}
.o-padding-left-large {
  padding-left: 1rem !important;
}

.o-padding-horizontal-none {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.o-padding-vertical-none {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.o-padding-vertical-large {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.l-padding-horizontal-15p {
  padding-left: 15%;
  padding-right: 15%;
}

.l-padding-horizontal-5p {
  padding-left: 5%;
  padding-right: 5%;
}

/*
 Chips
------------*/
.l-chipstyle {
  padding: 7px 12px;
  font-size: 0.6rem;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
}

.l-chiplabel {
  padding: 4px 8px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
}

/*
 Buttons
------------*/
.l-button-light {
  background-color: var(--shade-100);
  color: var(--primary-500);
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.l-button-light:hover {
  background-color: var(--shade-200);
  cursor: pointer;
}

.l-button-light:disabled {
  background-color: var(--GreyScale4);
  color: var(--GreyScale3);
  cursor: default;
}

.l-button-semi-light {
  background-color: var(--ColorScale4);
  color: var(--ColorScale3);
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.l-button-semi-light:hover {
  background-color: var(--ColorScale3);
  color: var(--GreyScale5);
  cursor: pointer;
}

.l-button-dark {
  background-color: var(--primary-text);
  color: var(--ColorScale8) !important;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.l-button-dark:hover {
  opacity: 0.8;
}

.l-button-dark:disabled,
.l-button-dark[disabled] {
  background-color: var(--ColorScale4);
  color: var(--ColorScale8);
  cursor: default;
}

.l-button-inactive {
  background-color: var(--GreyScale4);
  color: var(--GreyScale3);
  border: 1px solid var(--GreyScale4);
  border-radius: 4px;
  font: inherit;
}

/*
 Fonts
------------*/

.l-font-helvetica {
  font-family: Helvetica, sans-serif;
}

.l-font-extra-micro {
  font-size: 0.4rem;
}

.l-font-micro {
  font-size: 0.6rem;
}

.l-lineheight-micro {
  line-height: 0.6rem;
}
.l-lineheight-mini {
  line-height: 0.7rem;
}
.l-line-height-medium {
  line-height: 1rem;
}
.l-line-height-large {
  line-height: 1rem;
}

.l-line-height-24 {
  line-height: 24px;
}

.l-line-height-30 {
  line-height: 30px;
}

.l-font-tiny-dynamic {
  font-size: max(0.45vw, 0.45rem);
}

.l-font-micro-dynamic {
  font-size: max(calc(0.2rem + 0.4vw), 0.6rem);
}

.l-font-micro-fully-dynamic {
  font-size: 0.6vw;
}
.l-height-micro-dynamic {
  height: 1.2vw;
}

.l-font-mini {
  font-size: 0.7rem;
}

.l-font-mini-dynamic {
  font-size: max(calc(0.3rem + 0.4vw), 0.6rem);
}

.l-font-small {
  font-size: 0.8rem;
}

.l-font-small-dynamic {
  font-size: calc(0.4rem + 0.4vw);
}

.l-font-medium {
  font-size: 1rem;
}

.l-font-medium-dynamic {
  font-size: calc(0.5rem + 0.5vw);
}

.l-font-large {
  font-size: 1.2rem;
}

.l-font-large-dynamic {
  font-size: 1.2vw;
}

.l-font-extra-large {
  font-size: 1.5rem;
}

.l-font-size-24 {
  font-size: 24px;
}

.l-font-extra-large-dynamic {
  font-size: 1.5vw;
}

.l-font-3rem {
  font-size: 3rem;
}

.o-size-mini {
  width: 0.7rem !important;
  height: 0.7rem !important;
}

.l-font-bold {
  font-weight: bold;
}

.l-font-normal {
  font-weight: normal;
}
.l-font-italic {
  font-style: italic;
}
.l-text-left {
  text-align: left;
}

.l-text-center {
  text-align: center;
}

.l-text-nowrap {
  white-space: nowrap;
}

.l-word-break {
  word-break: break-word;
}

.l-pre-wrap {
  white-space: pre-wrap;
}

.l-color-inherit {
  color: inherit;
}

.l-color-grey {
  color: rgba(0, 0, 0, 0.54);
}

.l-color-darkgrey {
  color: var(--gray-700);
}

.l-color-lightgrey {
  color: var(--GreyScale4);
}
.l-color-darkgreen {
  color: var(--ColorScale1);
}

.l-color-light {
  color: var(--ColorScale8);
}

.l-color-dark {
  color: var(--ColorScale3);
}

.l-color-label-grey {
  color: var(--GreyScale3);
}

.l-color-red {
  color: rgba(201, 33, 33, 0.76);
}
.l-color-orange {
  color: rgba(201, 109, 33, 0.842);
}
.l-color-yellow {
  color: rgba(202, 176, 25, 0.76);
}
.l-color-lightgreen {
  color: rgba(184, 201, 33, 0.712);
}
.l-color-green {
  color: rgba(33, 201, 33, 0.658);
}
.l-color-dimgrey {
  color: dimgray;
}

.l-font-logo {
  font-family: 'Foodop';
}
.l-font-size-extra-large {
  font-size: 2rem;
}

.l-line-height-19 {
  line-height: 19px;
}

.input-icon {
  float: right;
  margin-right: 6px;
  margin-top: -20px;
  position: relative;
  z-index: 2;
}

/*
 Borders
------------*/
.l-bottom-border {
  border-bottom: 1px solid var(--GreyScale4);
}

.l-top-border {
  border-top: 1px solid var(--GreyScale4);
}

.l-rightborder-grey {
  border-right: 1px solid var(--GreyScale3);
}

.l-border-radius {
  border-radius: 4px;
}

.l-border-radius-large {
  border-radius: 10px;
}

.l-border-radius-top-large {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.l-border {
  border: 1px solid var(--GreyScale4);
}

.l-border-darkgreen {
  border: 1px solid var(--ColorScale2);
}

.l-border-dotted {
  border: 1px dotted var(--GreyScale4);
}

.l-border-dotted-thick {
  border: 1px dotted var(--GreyScale2);
}

.l-border-light {
  border: 1px solid var(--ColorScale4);
}

.l-border-thick {
  border: 10px solid var(--GreyScale5);
}

.l-border-none {
  border: none;
}

.l-box-shadow {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.l-box-shadow-under {
  box-shadow: 0px 3px 2px 0px rgb(0 0 0 / 30%), 0px 5px 2px 0px rgb(0 0 0 / 13%), 0px 4px 5px 0px rgb(0 0 0 / 15%);
}

.l-box-shadow-onhover:hover {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.l-box-shadow-darkgrey-under {
  box-shadow: 0px 0px 5px 0px rgb(10, 10, 10);
}

.l-box-shadow-top-grey {
  box-shadow: 0px -4px 6px 0px var(--GreyScale3);
}
.l-box-shadow-top-grey-small {
  box-shadow: 0px 0px 4px 0px var(--GreyScale3);
}

.l-box-shadow-grey {
  box-shadow: 0px 0px 5px 0px lightgray;
}

.l-box-shadow-green {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%), 0px 0px 2px 0px rgb(0 0 0 / 14%), 0px 0px 5px 0px rgb(0 0 0 / 12%);
}

.o-box-shadow-green {
  box-shadow: 0px 0px 10px 0px rgb(36 112 103 / 50%), 0px 0px 2px 0px rgb(36 112 103 / 30%), 0px 0px 5px 0px rgb(36 112 103 / 15%) !important;
}

.l-box-shadow-pulse {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%), 0px 0px 2px 0px rgb(0 0 0 / 14%), 0px 0px 5px 0px rgb(0 0 0 / 12%);
  animation: pulsate 2s ease-out infinite;
}

@-webkit-keyframes pulsate {
  0% {
    box-shadow: 0 0 2px var(--ColorScale5);
  }
  50% {
    box-shadow: 0 0 10px var(--ColorScale5);
  }
  100% {
    box-shadow: 0 0 2px var(--ColorScale5);
  }
}

/*
 Backgrounds
 -----------*/

.l-background-overlay {
  background-color: rgba(0, 0, 0, 0.54);
}

.l-background-lightgrey {
  background-color: var(--GreyScale4);
}

.l-background-grey {
  background-color: var(--ColorScale4);
}

.l-background-white {
  background-color: var(--white);
}
.l-background-darkgrey {
  background-color: var(--GreyScale2);
}

.l-background-darkgreen {
  background-color: var(--ColorScale2);
}

.l-background-lightgreen {
  background-color: var(--ColorScale8);
}

.l-theme-green {
  background-color: var(--ColorScale9);
  color: var(--ColorScale8);
}

.l-theme-darkgreen_old {
  background-color: var(--ColorScale3);
  color: var(--ColorScale8);
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.l-theme-darkgreen {
  background-color: var(--ColorScale3);
  color: var(--ColorScale8);
}

.l-theme-lightgreen {
  background-color: var(--ColorScale8);
  color: var(--ColorScale1);
}

.l-theme-lightgrey {
  background-color: var(--GreyScale6);
  color: var(--ColorScale8);
}

.l-theme-darkcyan {
  background-color: darkcyan;
  color: var(--ColorScale8);
}

.l-theme-grey {
  background-color: var(--GreyScale4);
  color: var(--ColorScale1);
}

.l-theme-grey-green {
  background-color: var(--ColorScale5);
  color: var(--ColorScale8);
}

.l-theme-accent1 {
  background-color: var(--accent-1);
  color: var(--ColorScale8);
}

.l-theme-accent4 {
  background-color: var(--accent-4);
  color: var(--ColorScale3);
}

.l-theme-accent5 {
  background-color: var(--accent-5);
  color: var(--ColorScale8);
}

.o-theme-accent1 {
  background-color: var(--accent-1) !important;
  color: var(--ColorScale8) !important;
}

.l-theme-white {
  background-color: white;
  color: var(--primary-text);
}

.l-gradient-sides {
  background-image: linear-gradient(90deg, rgba(240, 247, 244, 1) 0%, rgba(240, 247, 244, 0) 10%, rgba(240, 247, 244, 0) 90%, rgba(240, 247, 244, 1) 100%);
}

/*
 Icons
------------*/
.button-icon-large {
  padding-right: 0.5rem;
}

.l-icon-red {
  color: var(--accent-3);
}

.l-icon-dimmed {
  color: var(--gray-300) !important;
}

.l-icon-grey {
  color: var(--gray-500) !important;
}

/*
 Cursor
 -----------*/
.l-cursor-move {
  cursor: move;
}

.l-cursor-pointer {
  cursor: pointer;
}
.o-cursor-pointer {
  cursor: pointer !important;
}
.l-cursor-grab {
  cursor: grab;
}

.l-cursor-default {
  cursor: default;
}

.o-cursor-default {
  cursor: default !important;
}

/*
 Input
 -----------*/
.l-input-disguised {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

.l-input-disguised:hover {
  border: 1px solid var(--GreyScale4);
  border-radius: 4px;
  background-color: transparent;
}

.l-input-transparent {
  background-color: transparent;
  color: inherit;
  outline: none;
}

.l-input-transparent:focus-visible {
  background-color: transparent;
  color: inherit;
  outline: none;
}

/*
 ...
------------*/
.l-tile {
  background-color: var(--GreyScale5);
  padding: 20px;
  margin-bottom: 1rem;
}

.l-shadowbox {
  border: 1px solid var(--GreyScale4);
  box-shadow: 3px 3px 5px 0px var(--GreyScale4);
}

.l-line-spaced {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.l-new-section {
  margin-top: 2rem;
}

.l-content {
  padding: 1rem;
  overflow: hidden;
}

.l-transparent {
  background-color: transparent;
}

.o-transparent {
  background-color: transparent !important;
}

/*===================
		STATES
===================*/

.is-hidden {
  display: none;
}
.o-is-hidden {
  display: none !important;
}
.is-visible {
  visibility: visible;
}

.is-invisible {
  visibility: hidden;
}

.l-dimmed {
  color: dimgray;
}

.fade-in-out {
  animation: fadeInOut 4s; /* Fading effect takes 4 second */
}

/* Go from zero to full opacity and back */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

.mat-chip {
  background-color: var(--chip-bg) !important;
  color: var(--chip-text) !important;
  font-size: 0.6rem !important;
  cursor: pointer !important;
}

.l-test-div1 {
  border: red 1px solid;
  background-color: lightpink;
}

.l-test-div2 {
  border: blue 1px solid;
  background-color: lightblue;
}
.l-test-div3 {
  border: green 1px solid;
  background-color: lightgreen;
}
/* .mat-form-field-infix {
    width: 150px !important;
    line-height: 1.2 !important;
} */

.mat-button-toggle-checked {
  background-color: var(--ColorScale3) !important;
  color: var(--GreyScale5) !important;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

tbody.mat-calendar-body tr:nth-child(odd) {
  background-color: white;
}

tbody.mat-calendar-body tr:nth-child(even) {
  background-color: var(--ColorScale8);
}

td.mat-calendar-body-label {
  background-color: var(--ColorScale3);
  color: var(--GreyScale5) !important;
}

.l-rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.l-rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.l-rotate-270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.l-fit-contain {
  object-fit: contain;
}

span.material-icons.icon-true {
  color: var(--accent-1) !important;
}
span.material-icons.icon-false {
  color: var(--accent-3) !important;
}
span.material-icons.icon-warn {
  color: var(--yellow-300) !important;
}
span.material-icons {
  font-size: 1.2rem !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
}
